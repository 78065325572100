'use client';

import '@frontend/utils/jsonSerialize';

import { Flex } from '@radix-ui/themes';
import { darkTheme, RainbowKitProvider } from '@rainbow-me/rainbowkit';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { SessionProvider } from 'next-auth/react';
import { AppProgressBar as ProgressBar } from 'next-nprogress-bar';
import { SnackbarProvider } from 'notistack';
import { ReactNode, useEffect, useMemo } from 'react';
import { cookieToInitialState, WagmiProvider } from 'wagmi';
import posthog from 'posthog-js';
import { PostHogProvider } from 'posthog-js/react';
import { generateWagmiConfig } from '@frontend/client/rainbowkit';
import { useYodlStore } from '@frontend/client/contexts/useYodlStore';
import { AblyProvider } from 'ably/react';
import * as Ably from 'ably';
import { v4 } from 'uuid';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

if (typeof window !== 'undefined' && process.env.NODE_ENV !== 'development') {
  if (
    !process.env.NEXT_PUBLIC_POSTHOG_KEY ||
    !process.env.NEXT_PUBLIC_POSTHOG_HOST
  ) {
    console.error('Posthog not configured');
  }

  posthog.init(process.env.NEXT_PUBLIC_POSTHOG_KEY!, {
    api_host: process.env.NEXT_PUBLIC_POSTHOG_HOST,
    capture_pageleave: true, // Enable automatic pageleave capture
  });
}

if (typeof window !== 'undefined' && process.env.NODE_ENV !== 'development') {
  if (!process.env.NEXT_PUBLIC_ABLY_API_KEY) {
    console.error('Ably not configured');
  }
}

const client = new Ably.Realtime({
  key: process.env.NEXT_PUBLIC_ABLY_API_KEY,
  clientId: v4(),
});

const queryClient = new QueryClient();

type ProvidersProps = {
  children: ReactNode;
  initialCookies: string | null | undefined;
};

export function Providers({ children, initialCookies }: ProvidersProps) {
  const isPlaywright = useYodlStore((state) => state.isPlaywright);
  const setPlaywright = useYodlStore((state) => state.setPlaywright);
  const rainbowTheme = darkTheme();

  useEffect(() => {
    // DO NOT use the useSearchParams hook
    // It causes Rainbowkit to disconnect from the user's wallter for unknown reasons
    // the following code breaks the Rainbowkit button
    // const urlParams = useSearchParams();

    const urlParams = new URLSearchParams(window.location.search);
    const playwrightParam = urlParams.get('playwright');

    // set refid in localstorage
    const refid = urlParams.get('refid');
    if (refid && refid != '') {
      // do not overwrite refids, first refid counts.
      if (!localStorage.getItem('refid')) {
        localStorage.setItem('refid', refid);
      }
    }

    setPlaywright(playwrightParam === '1');
  }, [setPlaywright]);

  const wagmiConfig = useMemo(
    () =>
      generateWagmiConfig({
        isPlaywright: isPlaywright,
      }),
    [isPlaywright],
  );

  // initialCookies see: https://wagmi.sh/react/guides/ssr
  const initialState = useMemo(
    () => cookieToInitialState(wagmiConfig, initialCookies),
    [wagmiConfig, initialCookies],
  );

  const content = (
    <PostHogProvider client={posthog}>
      <SessionProvider>
        <SnackbarProvider autoHideDuration={3000} preventDuplicate>
          <AblyProvider client={client}>{children}</AblyProvider>
          <ProgressBar
            height="4px"
            options={{ showSpinner: false }}
            shallowRouting
          />
        </SnackbarProvider>
      </SessionProvider>
    </PostHogProvider>
  );

  return (
    <Flex direction="column">
      <WagmiProvider
        config={wagmiConfig}
        reconnectOnMount={true}
        initialState={initialState}
      >
        <QueryClientProvider client={queryClient}>
          <RainbowKitProvider theme={rainbowTheme}>
            {content}
          </RainbowKitProvider>
          <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
      </WagmiProvider>
    </Flex>
  );
}
