import {
  getDefaultConfig,
  getDefaultWallets,
  Wallet,
  WalletDetailsParams,
} from '@rainbow-me/rainbowkit';
import {
  argentWallet,
  ledgerWallet,
  trustWallet,
} from '@rainbow-me/rainbowkit/wallets';
import { Address, Chain, http } from 'viem';
import { Config, cookieStorage, createConnector, createStorage } from 'wagmi';
import { mock } from '@wagmi/core';
import { defaultPublicRpc } from '@frontend/utils/env.client';
import { RainbowKitChain } from '@rainbow-me/rainbowkit/dist/components/RainbowKitProvider/RainbowKitChainContext';
import { SUPPORTED_CHAINS } from '@frontend/common';
import parseTokenImages from '@frontend/utils/parseImages';
import { chainById } from '@frontend/utils/chains';

type WagmiConfigParams = {
  supportedChains?: Chain[];
  isPlaywright?: boolean;
};

const fixupGnosis = (gnosisChain: RainbowKitChain) => {
  const { chainInfo } = chainById(gnosisChain.id);
  gnosisChain.iconUrl = parseTokenImages(chainInfo.logoUri);
  gnosisChain.iconBackground = '#fff';
};

const walletConnectMeta = {
  appName: 'yodl',
  appDescription: 'yodl',
  // required by wallet connect. if it does not match, then some wallets
  // will show a nasty warning. You have to enable verify api on walletconnect cloud.
  appUrl: 'https://yodl.me',
  // TODO: Move elsewhere
  appIcon:
    'https://framerusercontent.com/images/AAmKW17l9jseK4hJPDy2uYxVEdU.png',
};

export const mockedAccounts: readonly [Address, ...Address[]] = [
  '0xf39Fd6e51aad88F6F4ce6aB8827279cffFb92266',
  '0x70997970c51812dc3a010c7d01b50e0d17dc79c8',
  '0x3C44CdDdB6a900fa2b585dd299e03d12FA4293BC',
];

export function mockWallet() {
  return (): Wallet => ({
    id: 'mock',
    name: 'MockWallet',
    iconBackground: '#fff',
    iconUrl: '',
    installed: true,
    createConnector: (walletDetails: WalletDetailsParams) => {
      return createConnector((config) => ({
        ...mock({
          accounts: mockedAccounts,
          // @ts-ignore
        })(config),
        ...walletDetails,
      }));
    },
  });
}

export const generateWagmiConfig = ({
  isPlaywright = false,
}: WagmiConfigParams): Config => {
  const projectId = process.env.NEXT_PUBLIC_WALLET_CONNECT_PROJECT_ID || '';

  const createTransports = () => {
    return Object.fromEntries(
      SUPPORTED_CHAINS.map((chain) => [
        chain.id,
        http(defaultPublicRpc(chain.id)),
      ]),
    );
  };

  const transports = createTransports();
  const { wallets } = getDefaultWallets();

  const myWallets = [
    ...wallets.map((wallet) => ({ ...wallet, shimDisconnect: true })),
    {
      groupName: 'Other',
      wallets: [argentWallet, trustWallet, ledgerWallet],
    },
  ];
  if (isPlaywright) {
    myWallets.push({
      wallets: [mockWallet()],
      groupName: 'mock',
    });
  }

  const wagmiConfig = getDefaultConfig({
    chains: SUPPORTED_CHAINS,
    transports,
    multiInjectedProviderDiscovery: false,
    projectId,
    ...walletConnectMeta,
    ssr: true,
    storage: createStorage({
      storage: cookieStorage,
    }),
    wallets: myWallets,
  });

  // Rainbow doesn't officially support Gnosis so we monkeypatch the object
  const gnosisChain = wagmiConfig.chains.find((c) => c.id == 100);
  if (gnosisChain) {
    fixupGnosis(gnosisChain);
  }

  return wagmiConfig;
};
