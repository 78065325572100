'use client';

import Link from 'next/link';
import { Flex, TabNav } from '@radix-ui/themes';
import { usePathname, useRouter } from 'next/navigation';

export function WalletHeader() {
  const pathname = usePathname();

  const isActive = (path: string) => {
    return pathname.startsWith(path);
  };

  return (
    <Flex justify="center" style={{}}>
      <TabNav.Root justify="center" style={{ width: '100%' }}>
        <TabNav.Link asChild active={isActive('/receive')}>
          <Link href="/receive">Receive</Link>
        </TabNav.Link>
        <TabNav.Link
          asChild
          active={!isActive('/receive') && !isActive('/offramp')}
        >
          <Link href="/send">Send</Link>
        </TabNav.Link>
        <TabNav.Link asChild active={isActive('/offramp')}>
          <Link href="/offramp">Offramp</Link>
        </TabNav.Link>
      </TabNav.Root>
    </Flex>
  );
}
