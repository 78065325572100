'use client';
import { useEffect, useState } from 'react';

export const useCopyToClipboard = (
  resetInterval = 2000,
): [boolean, (text: string) => Promise<boolean>] => {
  const [isCopied, setIsCopied] = useState<boolean>(false);

  const copy = async (text: string) => {
    if (!navigator?.clipboard) {
      // logger.warn("Clipboard not supported");
      return false;
    }

    try {
      await navigator.clipboard.writeText(text);
      setIsCopied(true);
      return true;
    } catch (error) {
      // logger.error("Copy failed", error);
      setIsCopied(false);
      return false;
    }
  };

  useEffect(() => {
    let timeout: NodeJS.Timeout;
    if (isCopied && resetInterval) {
      timeout = setTimeout(() => setIsCopied(false), resetInterval);
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [isCopied, resetInterval]);

  return [isCopied, copy];
};
