import { Prisma } from '@prisma/client';

interface BigInt {
  /** Convert to BigInt to string form in JSON.stringify */
  toJSON: () => string;
}

// @ts-ignore
BigInt.prototype.toJSON = function () {
  return this.toString();
};

Prisma.Decimal.prototype.toJSON = function () {
  return this.toString();
};
