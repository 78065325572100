'use client';
import Image from 'next/image';
import logoDark from '@frontend/public/assets/images/logo.svg';
import { Flex, Separator } from '@radix-ui/themes';
import Link from 'next/link';
import { ChainSelector } from './ChainSelector';
import { CustomConnect } from '@frontend/app/(routes)/send/components/footer/CustomConnect';
import { useAccount } from 'wagmi';

export function Header() {
  const { isConnected } = useAccount();

  return (
    <Flex
      justify="between"
      width="100%"
      style={{
        borderBottom: '1px solid var(--gray-a4)',
      }}
    >
      <Link href="/send">
        <Image
          src={logoDark}
          alt="Yodl Logo"
          width={70}
          priority
          className="ml-1 mr-4"
        />
      </Link>
      <Flex justify="end">
        <ChainSelector />
        {isConnected && <Separator orientation="vertical" size="4" />}
        <CustomConnect header={true} />
      </Flex>
    </Flex>
  );
}
