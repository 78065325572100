'use client';

import { useYodlStore } from '@frontend/client/contexts/useYodlStore';
import {
  useChainActivities,
  useSelectableChains,
} from '@frontend/client/hooks';
import { Avatar, Flex, Select, Spinner, Text } from '@radix-ui/themes';
import { useAccount } from 'wagmi';
import { chainById } from '@frontend/utils/chains';

export function ChainSelector() {
  const { isConnected } = useAccount();

  const receiver = useYodlStore((s) => s.receiver);

  const { isLoading, isPending } = useChainActivities(receiver);

  const {
    selectableChains,
    chainIdIfSelectable,
    isDataLoadedAndChainNotUsed,
    setChain,
    currentChain,
    hasActivityOrExplicitlyEnabled,
  } = useSelectableChains();

  return (
    <>
      {isPending && isLoading && (
        <Flex align="center">
          <Spinner mr="2" />
        </Flex>
      )}

      {isConnected && (
        <Select.Root
          size="2"
          value={chainIdIfSelectable}
          disabled={!isConnected}
          onValueChange={(chainId) => setChain(chainId)}
        >
          <Select.Trigger
            variant="ghost"
            color={isDataLoadedAndChainNotUsed ? 'orange' : undefined}
            placeholder={!isConnected ? 'Connect wallet' : 'Select chain'}
            disabled={!isConnected}
            style={{
              padding: '5px 15px 5px 5px',
              height: 'auto',
              margin: 0,
              borderRadius: 0,
            }}
          >
            {currentChain && (
              <Avatar
                src={chainById(currentChain?.id)?.chainInfo.logoUri}
                size="1"
                fallback=""
              />
            )}
          </Select.Trigger>
          <Select.Content position="popper">
            <Select.Group>
              {selectableChains.map((chain) => {
                return (
                  <Select.Item
                    key={chain.id}
                    value={chain.id.toString()}
                    className="py-4"
                  >
                    <Flex gap="3" justify="start" align="center">
                      <Avatar
                        src={chainById(chain.id).chainInfo.logoUri}
                        size="1"
                        fallback=".."
                      />
                      <Text
                        className={
                          hasActivityOrExplicitlyEnabled(chain)
                            ? ''
                            : 'opacity-50'
                        }
                      >
                        {chain.name}
                      </Text>
                    </Flex>
                  </Select.Item>
                );
              })}
            </Select.Group>
          </Select.Content>
        </Select.Root>
      )}
    </>
  );
}
