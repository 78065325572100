'use client';

import { MoonIcon } from '@radix-ui/react-icons';
import { Button } from '@radix-ui/themes';
import { useTheme } from 'next-themes';

export function ThemeToggleButton({ size }) {
  const { theme, setTheme } = useTheme();

  function toggleTheme() {
    const newTheme = theme == 'dark' ? 'light' : 'dark';
    setTheme(newTheme);
  }
  return (
    <Button size={size} variant="soft" onClick={toggleTheme}>
      <MoonIcon />
    </Button>
  );
}
