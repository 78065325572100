'use client';

import { getViemChain } from '@frontend/common';

export function defaultPublicRpc(chainId) {
  const chain = getViemChain(chainId);
  if (!chain) {
    throw new Error(`Chain ${chainId} not found`);
  }
  return chain.rpcUrls.default.http[0];
}
